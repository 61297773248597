<template>
	<div>
		<div class="cart-grid">
			<div
				:class="'cart-items'"
				v-for="(item, index) in cart"
				:key="'cart' + index"
			>
				<div
					class="cart-info"
					@touchstart="touchStart"
					@touchend="touchend($event, item, index)"
				>
					<div class="cart-btn" @click="handleEditProduct(item, index)">
						<img src="../assets/pen.svg" />
					</div>
					<div class="cart-name">{{ item.itemName }}</div>
					<ByAmountPicker
						v-model="item.count"
						@onIncrease="cartItem('plus', item, index, $event)"
						@onMinus="cartItem('minus', item, index, $event)"
						@onDelete="deleteItem(item, index)"
						:isUpdate="false"
					/>
					<div class="cart-price">
						${{ $stepPrice($countProductPrice(item)) }}
					</div>
					<OrderList class="cart-order-list" :data="item" />
					<div v-if="item.automaticDiscountId" class="cart-discount">
						<p>折扣：</p>
						<div class="cart-discount-content">
							<span> 「{{ item.automaticDiscountName }}」 </span>
							<span>-${{ Math.floor(item.discountPrice * item.count) }}</span>
						</div>
					</div>
				</div>
				<div
					class="cart-delete"
					@click="onShowDeleteCartItemDialog(item, index)"
				>
					<div class="trashcan">
						<img src="../assets/trashcan.svg" />
					</div>
				</div>
			</div>
		</div>

		<!--	手機點餐下顯示訂單自動折扣	-->
		<template v-if="!isKiosk">
			<div class="cart-order-discount">
				<div class="cart-order-discount-use" v-if="useOrderAutomaticDiscount">
					<span>「{{ useOrderAutomaticDiscount.name }}」</span>
					<span>-${{ orderAutomaticDiscountPrice }}</span>
				</div>
				<div class="cart-order-discount-next" v-if="nextOrderAutomaticDiscount">
					*差${{
						nextOrderAutomaticDiscount.minimumSpendingAmount - cartTotal
					}}元 即可達到 「{{ nextOrderAutomaticDiscount.name }}」
				</div>
			</div>
		</template>

		<div class="cart-count" v-if="!noCount">
			<span>總計</span>
			<span>$ {{ $stepPrice(cartTotal - orderAutomaticDiscountPrice) }}</span>
		</div>
		<ByDialog
			class="clear-cart-confirm"
			:value="isDeleteCartItemDialog"
			width="320px"
			:title="`是否刪除${cacheDeleteCartItemState.name}`"
			@closeDialog="onHideDeleteCartItemDialog"
		>
			<template slot="dialog-footer">
				<div class="dialog-footer">
					<ByButton stl="main2" @click="onHideDeleteCartItemDialog"
						>否
					</ByButton>
					<ByButton stl="main1" @click="deleteCartItem">是</ByButton>
				</div>
			</template>
		</ByDialog>
	</div>
</template>
<script>
import { mapMutations } from 'vuex'
import OrderList from '@/views/buy/Components/OrderList'

export default {
	name: 'ByCartGrid',
	components: { OrderList },
	props: {
		noCount: {
			type: Boolean,
			required: false,
			default: false,
		},
		// 取代原本的 handleEditProduct
		editFun: {
			type: Function,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			startX: 0, //開始觸摸的位置
			endX: 0, //結束觸摸的位置
			isDeleteCartItemDialog: false,
			cacheDeleteCartItemState: {
				index: 0,
				name: '',
			},
		}
	},
	computed: {
		cart() {
			return this.$store.getters['user/currCart']
		},
		cartTotal() {
			return this.$store.getters['user/cartTotal']
		},
		// 訂單自動折扣金額(折數換算金額、金額)
		orderAutomaticDiscountPrice() {
			return this.$store.state.user.orderAutomaticDiscountPrice
		},
		// 套用的訂單自動折扣
		useOrderAutomaticDiscount() {
			return this.$store.state.user.useOrderAutomaticDiscount
		},
		// 目標訂單自動折扣
		nextOrderAutomaticDiscount() {
			return this.$store.state.user.nextOrderAutomaticDiscount
		},
		// 是否前台點餐
		isKiosk() {
			return this.$store.getters.isKiosk
		},
	},
	methods: {
		...mapMutations('user', ['REMOVE_CART_ITEM', 'UPDATE_CART_ITEM']),
		handleEditProduct(item, index) {
			this.$store.commit('detail/setCacheCart', { data: item, index })
			this.editFun != null
				? this.editFun(item.itemId)
				: this.$router.push('Detail/' + item.itemId + '?edit=true')
		},
		touchStart(e) {
			this.startX = Math.floor(e.touches[0].pageX)
		},
		touchend(e, item, index) {
			this.endX = Math.floor(e.changedTouches[0].pageX)
			if (this.endX - this.startX < -30) {
				this.cartItem('moveLeft', item, index)
			} else {
				this.cartItem('moveRight', item, index)
			}
		},
		onShowDeleteCartItemDialog(item, index) {
			this.cacheDeleteCartItemState.index = index
			this.cacheDeleteCartItemState.name = item.itemName
			this.isDeleteCartItemDialog = true
		},
		onHideDeleteCartItemDialog() {
			this.isDeleteCartItemDialog = false
		},
		deleteCartItem() {
			const {
				cacheDeleteCartItemState: { index },
			} = this
			const cartInfos = [...document.getElementsByClassName('cart-info')]
			const deleteBtn = [...document.getElementsByClassName('cart-delete')]
			this.cart.map((row, i) => {
				if (i === index) {
					this.REMOVE_CART_ITEM({ index: i })
				}
			})
			cartInfos.forEach((info, i) => {
				if (i === index) {
					info.style.transition = 'initial'
					info.style.right = '0px'
					deleteBtn.forEach((del, _index) => {
						if (_index === index) {
							del.style.transition = 'initial'
							del.style.right = '-70px'
						}
					})
				}
			})
			this.onHideDeleteCartItemDialog()
			this.$store.commit('user/SET_CURRENT_ORDER_AUTOMATIC_DISCOUNT', {
				cartTotal: this.cartTotal,
				list: this.$store.state.store.orderAutomaticDiscountList,
			})
			this.$alert('已刪除成功', 'success')
		},
		deleteItem(item, index) {
			this.onShowDeleteCartItemDialog(item, index)
		},
		cartItem(type, item, index, eventCount = 0) {
			let cartInfos = [...document.getElementsByClassName('cart-info')]
			let deleteBtn = [...document.getElementsByClassName('cart-delete')]
			switch (type) {
				case 'moveLeft':
					cartInfos.forEach((info, i) => {
						if (i === index) {
							info.style.transition = 'all 0.5s ease'
							info.style.right = '70px'
							deleteBtn.forEach((del, _index) => {
								if (_index === index) {
									del.style.transition = 'all 0.5s ease'
									del.style.right = '0px'
								}
							})
						}
					})
					break
				case 'moveRight':
					cartInfos.forEach((info, i) => {
						if (i === index) {
							info.style.transition = 'all 0.5s ease'
							info.style.right = '0px'
							deleteBtn.forEach((del, _index) => {
								if (_index === index) {
									del.style.transition = 'all 0.5s ease'
									del.style.right = '-70px'
								}
							})
						}
					})
					break
				case 'minus':
					this.UPDATE_CART_ITEM({
						index: index,
						data: {
							...item,
							count: eventCount,
						},
					})
					if (item.count === 1 && eventCount === 1) {
						cartInfos.forEach((info, _i) => {
							if (_i === index) {
								info.style.transition = 'all 0.5s ease'
								info.style.right = '70px'
								deleteBtn.forEach((del, _index) => {
									if (_index === index) {
										del.style.transition = 'all 0.5s ease'
										del.style.right = '0px'
									}
								})
							}
						})
					}
					break
				case 'plus':
					this.UPDATE_CART_ITEM({
						index: index,
						data: {
							...item,
							count: eventCount,
						},
					})
					break
				default:
					break
			}
			this.$store.commit('user/SET_CURRENT_ORDER_AUTOMATIC_DISCOUNT', {
				cartTotal: this.cartTotal,
				list: this.$store.state.store.orderAutomaticDiscountList,
			})
		},
	},
}
</script>
<style scoped lang="sass">

.cart
	&-grid
		font-size: 16px

	&-items
		position: relative
		border-bottom: 1px solid #D8D8D899
		overflow: hidden

		&:last-child
			border-bottom: 25px
			border-bottom: 1px solid #D8D8D899

	&-btn
		width: 15px

		img
			width: 100%
			height: 100%

	&-info
		width: 100%
		padding: 15px
		display: flex
		flex-wrap: wrap
		align-items: center
		box-sizing: border-box
		float: left
		position: relative
		right: 0
		transition: all 0.5s ease

	&-name
		flex: 1
		padding: 0 10px

	&-price
		position: relative
		top: -3px
		flex-basis: 20%
		text-align: right
		color: #432D2C

	&-order-list
		margin-left: 25px

	&-discount
		color: #f37201
		width: 100%
		margin-left: 25px
		font-size: 14px

		&-content
			margin-left: 12px
			margin-top: 7px
			display: flex
			justify-content: space-between

	&-order-discount

		position: relative
		padding: 20px 15px 0 15px

		&-use
			color: #f37201
			display: flex
			justify-content: space-between

		&-next
			margin-top: 15px
			//font-weight: 700
			//font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif
			color: #D9001B

	&-count
		position: relative
		display: flex
		justify-content: space-between
		margin-bottom: 25px
		font-weight: bold
		font-size: 18px

		span
			padding: 20px 15px

		span:nth-last-child(1)
			color: #432D2C

	&-delete
		width: 70px
		color: #707070
		background-color: #eeeeee
		padding: 5px 10px
		font-size: 16px
		text-align: center
		transition: all 0.5s ease
		position: absolute
		top: 0
		right: -70px
		float: left
		height: 100%
		box-sizing: border-box

		.trashcan
			width: 16px
			position: relative
			left: 50%
			top: 50%
			transform: translate(-50%, -50%)

			&:after
				content: '刪除'
				display: block
				position: relative
				width: 30px
				left: -7px
				padding-top: 3px
				font-size: 14px

		p
			position: relative
			left: 50%
			top: 50%
			transform: translate(-50%, -50%)
</style>
