export default {
	namespaced: true,
	// modules: {}
	state: {
		openInfo: null,
		uri: null,
		menus: null,
		refreshMenu: false, // 刷新店家菜單
		refreshSetting: false, // 刷新店家設定
		whatsEatShopId: null, //WhatsEat綁定店家ID
		whatsEatCouponAmount: null, //WhatsEat優惠卷數量
		itemAutomaticDiscountList: [], // 商品自動折扣列表
		orderAutomaticDiscountList: [], // 訂單自動折扣列表
	},
	mutations: {
		SET_TAKE_OPEN_INFO($, value) {
			$.openInfo = value
		},
		SET_URI($, value) {
			$.uri = value
		},
		SET_MENUS($, value) {
			$.menus = value
		},
		SET_REFRESH_SETTING($, value) {
			$.refreshSetting = value
		},
		SET_REFRESH_MENU($, value) {
			$.refreshMenu = value
		},
		SET_WHATS_EAT_SHOP_ID($, value) {
			$.whatsEatShopId = value
		},
		SET_WHATS_EAT_COUPON_AMOUNT($, value) {
			$.whatsEatCouponAmount = value
		},
		SET_ITEM_AUTOMATIC_DISCOUNT_LIST($, value) {
			$.itemAutomaticDiscountList = value
		},
		SET_ORDER_AUTOMATIC_DISCOUNT_LIST($, value) {
			$.orderAutomaticDiscountList = value
		},
	},
	actions: {},
}
